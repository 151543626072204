import firebase from "firebase";

var firebaseConfig = {
  apiKey: "AIzaSyDP1No0dc0xQm8A95YF64wHTTuheK8LQDY",
  authDomain: "react-firebase-f472c.firebaseapp.com",
  databaseURL: "https://react-firebase-f472c.firebaseio.com",
  projectId: "react-firebase-f472c",
  storageBucket: "react-firebase-f472c.appspot.com",
  messagingSenderId: "362071408026",
  appId: "1:362071408026:web:76886bad12f04842e24e23",
  measurementId: "G-9X67W6XP6N",
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();

export { db };
